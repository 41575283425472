import * as React from "react"
import { Link } from "gatsby"
import Layout from "../components/layout"
import Seo from "../components/seo"

import Consultation from "../components/free-consultation"

// All the gatsby-plugin-image goodness
import { StaticImage } from "gatsby-plugin-image"



// React Bootstrap
import { Container } from "react-bootstrap"

const IndexPage = ({data}) => (
  <Layout>
    <Seo title="Architect Jim Kautz Can Help You Turn Your Ideas Into Realities!" />
    <Container>
      <h1 className="text-center mt-3 singleCol px-3">Architect Jim Kautz Can Help You Turn Your Ideas Into Realities!</h1>
      <p className="mt-4 text-center">
        <StaticImage 
          src="../images/adu-collage.jpg"
          alt="photos of ADU"
          width="800"
          placeholder="blurred"
        />
      </p>
      <h2 className="singleCol text-center">
      Create ADUs for rental, a caregiver, a guest room, or an office space.
      </h2>
    </Container>
    

    
    
    {/* <Container className="mt-3 singleCol">
      
      <Row>
        <Col lg={6}>
          <p>
            <StaticImage 
              src="../images/home-page/house-entry-sketch_orig.png"
              alt="Sketch of House"
              width="800"
              placeholder="blurred"
            />
        </p>
        </Col>
        <Col lg={6}>
          <p>
            <StaticImage 
              src="../images/home-page/entry-picture.jpg"
              alt="Photo of the front of a house"
              width="800"
              placeholder="blurred"
            />
          </p>
        </Col>
      </Row>
      
    </Container> */}

    <Container className="singleCol mt-3">
      <p>Architect Jim Kautz has been helping homeowners design house additions, extensive remodels, and accessory dwelling units (ADUs) for about 11 years. Steps include designing conceptual alternatives, drawing 3-dimensional visualizations, finding contractors and verifying prices, negotiating with planning and building departments, detailing construction documents, and assisting during construction.
      </p>
      <p className="text-center slab-text my-4">
        <Link to="/process/" className="btn btn-outline-dark">Learn About The Design Process</Link>
      </p>
      
    </Container>
    <Consultation />
  </Layout>
)

export default IndexPage


